import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@core/services/authentication.service';
import { ApiBase } from '@core/services';
import { apiEndpoints } from '@config/global-vars';
import { CredentialsService } from '@core/services/credentials.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SkipLineGuard implements CanActivate {
  constructor(
    public authService: AuthenticationService,
    public router: Router,
    public apiBase: ApiBase,
    private credentialsService: CredentialsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree
    | any {
    if (environment.production) {
      this.router.navigate(['', '/']);
      return false;
    }

    return this.apiBase.get(apiEndpoints.skip).pipe(
      catchError((error) => {
        this.router.navigate(['', '/']);
        throw error;
      }),

      switchMap((resp: any) => {
        if (resp?.data.token) {
          this.credentialsService.setCredentials({
            access_token: resp?.data.token,
          });
        }

        return this.apiBase.get(apiEndpoints.profile).pipe(
          map(({ data }) => {
            if (data.is_finished_survey) {
              this.router.navigate(['form', 'my-page']);
            } else {
              if (environment.getPointEndDate) {
                const current = new Date();
                const getPointEndDate = new Date(environment.getPointEndDate);

                if (current > getPointEndDate) {
                  this.router.navigate(['', '/']);
                  return;
                }
              }
              this.router.navigate(['form', 'survey']);
            }

            return data;
          })
        );
      })
    );
  }
}
