import { Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoadingOverlayService, ModalService } from '@core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'taiyou-user-angular';
  isPageNotFound = false;
  private readonly redirectScriptId = 'redirect-script';
  private readonly trackingCompleteScriptId = 'tracking-complete-script';
  private readonly inlineScriptId = 'inline-script';

  constructor(
    public router: Router,
    public loadingOverlayService: LoadingOverlayService,
    public modalService: ModalService,
    private _route: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe(() => {
        this.loadingOverlayService.isLoading = true;
      });

    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError
        )
      )
      .subscribe((event: any) => {
        this.loadingOverlayService.isLoading = false;
        this.checkLayout();
        window.scrollTo(0, 0);
        event.urlAfterRedirects === '/404'
          ? (this.isPageNotFound = true)
          : (this.isPageNotFound = false);
      });
  }

  checkLayout(): void {
    if (this._route.firstChild?.snapshot?.routeConfig?.path === '') {
      this.addTrackingScripts();
      document.getElementsByTagName('body')[0].style.backgroundColor =
        '#FDE930';
    } else {
      this.removeTrackingScripts();
      document.getElementsByTagName('body')[0].style.backgroundColor = '#fff';
    }
  }

  private addTrackingScripts(): void {
    // Add the redirect script to the head
    if (!document.getElementById(this.redirectScriptId)) {
      const redirectScript = document.createElement('script');
      redirectScript.src = 'https://chibijob.com/tracking-redirect.js';
      redirectScript.async = true;
      redirectScript.id = this.redirectScriptId;
      document.head.appendChild(redirectScript);
    }

    // Add the tracking complete script to the body
    if (!document.getElementById(this.trackingCompleteScriptId)) {
      const trackingCompleteScript = document.createElement('script');
      trackingCompleteScript.src = 'https://chibijob.com/tracking-complete.js';
      trackingCompleteScript.async = true;
      trackingCompleteScript.id = this.trackingCompleteScriptId;

      trackingCompleteScript.onload = () => {
        // Add the inline script
        if (!document.getElementById(this.inlineScriptId)) {
          const inlineScript = document.createElement('script');
          inlineScript.text = `
            setTimeout(() => {
              trackingComplete({
                adId: "5a9c4af9-f0cc-4fd8-8792-f9407e7386c4",
                amount: 15,
                status: 1,
                resultId: 0,
                uid: "",
                uid2: ""
              });
            }, 500);
          `;
          inlineScript.id = this.inlineScriptId;
          document.body.appendChild(inlineScript);
        }
      };

      document.body.appendChild(trackingCompleteScript);
    }
  }

  private removeTrackingScripts(): void {
    // Remove redirect script
    const redirectScript = document.getElementById(this.redirectScriptId);
    if (redirectScript) {
      redirectScript.remove();
    }

    // Remove tracking complete script
    const trackingCompleteScript = document.getElementById(
      this.trackingCompleteScriptId
    );
    if (trackingCompleteScript) {
      trackingCompleteScript.remove();
    }

    // Remove inline script
    const inlineScript = document.getElementById(this.inlineScriptId);
    if (inlineScript) {
      inlineScript.remove();
    }
  }
}
