// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// product
export const environment = {
  production: true,
  apiDomain: '',
  apiRoute: '/user/',
  appName: '',
  baseUrl: 'https://api.solato-2024wintercp.jp/api',
  urlAddFriend: 'https://line.me/R/ti/p/@405ctnjs',
  isAccessLine: true,
  getPointEndDate: '2025-02-03T10:59:59+09:00',
  closeCPDate: '2025-02-17T10:59:59+09:00',
};



// export const environment = {
//   production: false,
//   apiDomain: '',
//   apiRoute: '/user/',
//   appName: '',
//   baseUrl: 'https://taiyou-api.dev.dmk-campaign.com/api',
//   urlAddFriend: 'https://line.me/R/ti/p/@405ctnjs',
//   isAccessLine: true,
//   getPointEndDate: '2025-02-03T10:59:59+09:00',
//   closeCPDate: '2025-02-07T16:29:59+09:00',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
